import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.function.bind.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { EVENT_TYPE } from "../../enums/event-type";
import { customerContextCity, customerContextPostcode, ID_SELECTOR } from "../../customercontextlayer.src";
import { createCustomerContextCookie } from "../../services/customer-context-cookie-service";
import CustomerContext from "../../models/customer-context";
import { addTmsTrackingForChooseMyStoreButton } from "../../../common/tracking-tms";
import { retrieveCookieValueByName } from "../../../common/cookie-finder";
var STORE_CONTEXT_BUTTON = 'customer-context-button';
var STORE_SEARCH_RESULTS = 'store-search-results';
var storeIdCookieName;
var newStoreIdCookieName;
export function initStoreSearchResultData(storeContextLayerBlock) {
  storeIdCookieName = storeContextLayerBlock.dataset.storeidcookiename;
  newStoreIdCookieName = storeContextLayerBlock.dataset.newstoreidcookiename;
}
export function integrateHtmlToStoreSearchBlock(html, storeSearchBlock) {
  var storeSearchResultBlock = storeSearchBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_SEARCH_RESULTS, "\"]"));
  storeSearchResultBlock.innerHTML = html;
}
export function applyTrackingAndContextCookieOnStoreSearchBlockWithoutCoordinates(storeSearchBlock) {
  return applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, "", "");
}
export function applyTrackingAndContextCookieOnStoreSearchBlock(storeSearchBlock, latitude, longitude) {
  var _this = this;
  var storeContextualizationButtons = storeSearchBlock.querySelector("[".concat(ID_SELECTOR, "=\"").concat(STORE_CONTEXT_BUTTON, "\"]"));
  var storePageLink = storeSearchBlock.querySelectorAll("[".concat(ID_SELECTOR, "=\"a-store-page-link\"]"));
  storePageLink === null || storePageLink === void 0 || storePageLink.forEach(function (element) {
    element.addEventListener(EVENT_TYPE.CLICK, function () {
      addTmsTrackingForChooseMyStoreButton("see more about my store", "Contextualisation");
    }.bind(_this));
  });
  storeContextualizationButtons === null || storeContextualizationButtons === void 0 || storeContextualizationButtons.addEventListener(EVENT_TYPE.CLICK, function () {
    addTmsTrackingForChooseMyStoreButton('Choose localisation', 'Contextualisation', retrieveCookieValueByName("button_context_used"));
    var storeId = storeSearchBlock.querySelector('.js-form-checkbox:checked').value;
    createCustomerContextCookie(new CustomerContext(storeId, customerContextPostcode, customerContextCity, latitude, longitude), function () {
      window.location.reload();
    });
  });
}